<template>
  <v-dialog
    v-model="isFormOpen"
    width="600"
    persistent
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        fab
        small
        color="primary"
        :ripple="false"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ icons.mdiWrenchOutline }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ systemUnitName }} 発動条件
      </v-card-title>

      <v-card-text class="pb-10">
        <v-timeline
          dense
          reverse
        >
          <div
            v-for="(logicAttributes, logicsAttributesIdx) in logicsAttributes"
            :key="`logicsAttributes[${logicsAttributesIdx}]`"
          >
            <div v-if="!logicAttributes._destroy">
              <v-timeline-item hide-dot>
                <v-select
                  :value="{
                    payConditionId: +logicAttributes.payConditionId,
                    payConditionType: logicAttributes.payConditionType
                  }"
                  :items="conditionItems"
                  item-text="meta.name"
                  :item-value="item => {
                    if (item.attributes) {
                      return {
                        payConditionId: +item.id,
                        payConditionType: `${item.type[0].toUpperCase()}${item.type.slice(1)}`,
                      }
                    }
                  }"
                  :value-comparator="(a, b) => {
                    if (a && b) {
                      return +a.payConditionId === +b.payConditionId
                        && a.payConditionType === b.payConditionType
                    }
                  }"
                  :label="`条件${(logicsAttributesIdx + 1)}`"
                  :error="!(logicAttributes.payConditionId && logicAttributes.payConditionType)"
                  no-data-text="選択可能なデータがありません"
                  solo-inverted
                  hide-details
                  hide-selected
                  :append-outer-icon="logicsAttributes.length > 1 ? icons.mdiClose : ''"
                  :menu-props="{ auto: true, offsetY: true }"
                  @click:append-outer="remove(logicsAttributesIdx)"
                  @change="
                    logicAttributes.payConditionId = $event.payConditionId;
                    logicAttributes.payConditionType = $event.payConditionType"
                >
                  <template #item="{ item, on, attrs }">
                    <template v-if="!item.attributes">
                      <v-list-item-content v-text="item.header" />
                    </template>

                    <v-list-item
                      :disabled="isSelected(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.meta.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-timeline-item>

              <v-timeline-item
                v-if="!isLast(logicsAttributesIdx)"
                :icon="{
                  AND: icons.mdiGateAnd,
                  OR: icons.mdiGateOr,
                  XOR: icons.mdiGateXor,
                }[logicAttributes.gate]"
                fill-dot
                small
              >
                <v-select
                  v-model="logicAttributes.gate"
                  :items="gates"
                  hide-details
                  :menu-props="{ auto: true, offsetY: true }"
                />
              </v-timeline-item>

              <v-timeline-item v-else>
                <template #icon>
                  <v-btn
                    fab
                    small
                    :ripple="false"
                    color="primary"
                    @click="logicsAttributes.push({
                      ...defaultValue,
                      position: logicsAttributesIdx
                    })"
                  >
                    <v-icon>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-timeline-item>
            </div>
          </div>
        </v-timeline>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          :ripple="false"
          :disabled="changedCount <= 0 || !(isRequiredAttributesPresent && isUniq)"
          @click="$emit('submit', logicsAttributes); close()"
        >
          {{ logicsAttributes.some(o => o.id) ? '更新' : '作成' }}
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          :ripple="false"
          @click="close"
        >
          キャンセル
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api'
import {
  isEmpty,
  map,
  orderBy,
  every,
  cloneDeep,
  uniqBy,
  some,
} from 'lodash'
import {
  mdiGateAnd,
  mdiGateOr,
  mdiGateXor,
  mdiWrenchOutline,
  mdiBoomGateUpOutline,
  mdiPlus,
  mdiClose,
} from '@mdi/js'

export default {
  props: {
    systemUnitId: {
      type: Number,
      required: true,
      default: 0,
    },
    systemUnitName: {
      type: String,
      required: true,
      default: '',
    },
    systemUnitType: {
      type: String,
      required: true,
      default: '',
    },
    logics: {
      type: Array,
      default: () => [],
    },
    vendingPointConditions: {
      type: Array,
      required: true,
      default: () => [],
    },
    salesConditions: {
      type: Array,
      required: true,
      default: () => [],
    },
    contributionRatioConditions: {
      type: Array,
      required: true,
      default: () => [],
    },
    vendingSizeConditions: {
      type: Array,
      required: true,
      default: () => [],
    },
    referringSizeConditions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const defaultValue = {
      id: null,
      [`${props.systemUnitType}Id`]: props.systemUnitId,
      position: 0,
      payConditionType: null,
      payConditionId: null,
      gate: 'AND',
    }
    const gates = [
      { value: 'AND', text: '∧（かつ）' },
      { value: 'OR', text: '∨（または）' },
      { value: 'XOR', text: '⊻（もしくは）' },
    ]
    const gateEnum = gates.reduce((obj, val) => ({ ...obj, [val.value]: val.text }), {})

    const isFormOpen = ref(false)
    const logicsAttributes = ref([{ ...defaultValue }])
    const changedCount = ref(0)

    const conditionItems = computed(() => {
      return [
        { header: 'ポイント' },
        ...props.vendingPointConditions,
        { header: '売上' },
        ...props.salesConditions,
        { header: '給率' },
        ...props.contributionRatioConditions,
        { header: '販売' },
        ...props.vendingSizeConditions,
        { header: '紹介' },
        ...props.referringSizeConditions,
      ]
    })

    const isRequiredAttributesPresent = computed(() => {
      return every(logicsAttributes.value, o => {
        return o[`${props.systemUnitType}Id`]
          && o.payConditionType
          && o.payConditionId
          && o.gate
          && Number.isInteger(o.position)
      })
    })

    const isUniq = computed(() => {
      return uniqBy(logicsAttributes.value, o => {
        return [
          o[`${props.systemUnitType}Id`],
          o.payConditionType,
          o.payConditionId,
        ].join('')
      }).length === logicsAttributes.value.length
    })

    const remove = index => {
      if (logicsAttributes.value[index].id) {
        // eslint-disable-next-line no-underscore-dangle
        logicsAttributes.value[index]._destroy = true
      } else {
        logicsAttributes.value.splice(index, 1)
      }
    }

    const isLast = index => {
      const tails = logicsAttributes.value.slice((index + 1), conditionItems.value.length)

      if (isEmpty(tails)) return true

      return every(tails, '_destroy')
    }

    const isSelected = item => {
      if (!item.attributes) return false

      return some(logicsAttributes.value, o => {
        return +o.payConditionId === +item.id
          && o.payConditionType === `${item.type[0].toUpperCase()}${item.type.slice(1)}`
      })
    }

    const initLogicsAttributes = () => {
      if (isEmpty(props.logics)) {
        logicsAttributes.value = [{ ...defaultValue }]

        return
      }

      logicsAttributes.value = map(orderBy(props.logics, 'attributes.position'), (data, idx) => {
        const {
          payConditionType,
          payConditionId,
          gate,
        } = data.attributes

        return {
          id: data.id,
          payConditionType,
          payConditionId,
          position: idx,
          gate: gate || 'AND',
          [`${props.systemUnitType}Id`]: props.systemUnitId,
          _destroy: false,
        }
      })
    }

    const close = () => {
      isFormOpen.value = false
      changedCount.value = 0
      initLogicsAttributes()
    }

    onMounted(() => initLogicsAttributes())

    watch(() => cloneDeep(logicsAttributes.value), (newLogicsAttributes, _) => {
      if (!isFormOpen.value) return

      changedCount.value += 1
      emit('changed', newLogicsAttributes)
    })

    return {
      // data
      isFormOpen,
      gates,
      gateEnum,
      defaultValue,
      logicsAttributes,
      changedCount,

      // computed
      conditionItems,
      isRequiredAttributesPresent,
      isUniq,

      // methods
      remove,
      isLast,
      isSelected,
      close,

      icons: {
        mdiGateAnd,
        mdiGateOr,
        mdiGateXor,
        mdiWrenchOutline,
        mdiBoomGateUpOutline,
        mdiPlus,
        mdiClose,
      },
    }
  },
}
</script>
